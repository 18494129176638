import { Splide } from '@splidejs/splide';
gsap.registerPlugin(ScrollTrigger);

$(document).ready(function () {
    const header = $("#header");

    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $("#backtotop")
                .removeClass("opacity-0 invisible")
                .addClass("opacity-100 visible");
        } else {
            $("#backtotop")
                .removeClass("opacity-100 visible")
                .addClass("opacity-0 invisible");
        }
    });
    
    $("#backtotop").click(function () {
        $("html, body").animate(
            {
                scrollTop: "0px",
            },
            500
        );
    });

    //LIGHTGALLERY WORDPRESS
    $(".wp-block-gallery,.lightgallery")
        .lightGallery({
            download: false,
            selector: "a",
        })
        .on("onBeforeSlide.lg", function () {
            $(".lg-backdrop").css("opacity", "0.8");
            $(".lg-sub-html").css("opacity", "0");
        });

    //HEADER
    $('#menu-burger').click(function (){
        $(this).toggleClass('open');
        $('#mobile-menu').toggleClass('open');
        $(document.body).toggleClass('overflow-hidden');

        if(window.matchMedia("(max-width: 1279px)").matches) {
            header.removeClass("transparent");
        }
    });

    //SCROLL EVENTS
    let transparentBool = true;
    let stickyMenuPageHeaderBool = true;

    if($(window).scrollTop() >= 150) {
        header.removeClass("transparent");
    }
    if($(window).scrollTop() >= 550) {
        $("#sticky-menu-page-header").slideDown(300);
    }

    $(window).scroll(function () {
        //HEADER
        if (transparentBool && $(this).scrollTop() >= 150) {
            header.removeClass("transparent");
            transparentBool = false
        } else if (!transparentBool && $(this).scrollTop() < 150) {
            header.addClass("transparent");
            transparentBool = true
        }

        //STICKY MENU PAGE HEADER
        if(stickyMenuPageHeaderBool && $(this).scrollTop() >= 550){
            $("#sticky-menu-page-header").slideDown(300);
            stickyMenuPageHeaderBool = false;
        }else if(!stickyMenuPageHeaderBool && $(this).scrollTop() < 550){
            $("#sticky-menu-page-header").slideUp(300);
            stickyMenuPageHeaderBool = true;
        }
    });

    // Smooth scrolling with offset for anchor links
    $('a[href*="#"]').on('click', function(e) {
        if (this.hash !== "") {
            e.preventDefault();
            const hash = this.hash;
            const offset = 220;

            $('html, body').animate({
                scrollTop: $(hash).offset().top - offset
            }, 600);
        }
    });

    //MENU SUB-MENU
    // let currentTab = ""

    // header.click(function (e) {

    //     if(currentTab) {
    //         if(!(currentTab == e.target)) {
    //             currentTab.classList.remove("active")
    //             currentTab = ""
    //         }
    //     } 
    //     if(e.target.classList.contains("menu-item-has-children") ) {
    //         e.target.classList.toggle("active")
    //         currentTab = e.target
    //     }
    // })

    //MODAL
    $('.modal--event').click(function (e) {
        e.preventDefault();
        var content = $(this).find('.modal--content').html();
        openModal(content);
    });
    $('.modal--bg, #modal .close').click(function () {
        closeModal();
    });
    function openModal(content) {
        $('#modal').find('.container').append(content);
        $('.modal--container').addClass('show');
        $('html, body').addClass('overflow-hidden');
    }
    function closeModal() {
        $('.modal--container').removeClass('show');
        $('html, body').removeClass('overflow-hidden');
        setTimeout(function () {
            $('#modal').find('.container').empty();
        }, 300);
    }

    //ACCORDIONS
    $('.accordion .accordion--label').click(function (){
        $(this).toggleClass('active');
        $(this).parent().find('.accordion--content').stop().slideToggle(400);
    });

    
    //LISTING ETABLISSEMENT
    if(document.querySelector('.listing_etablissement')) {

        $('.listing_etablissement .etablissement .etablissement--label').click(function (){
            $(this).toggleClass('active');
            $(this).parent().find('.etablissement--content').stop().slideToggle(400);
        });
    
        if($('.listing_etablissement .etablissement .etablissement').length > 0){
            $('.listing_etablissement .etablissement .etablissement--content')[0].classList.remove("hidden")
            $('.listing_etablissement .etablissement .etablissement--label')[0].classList.add("active")
        }        
    }


    //FOOTER

    $('footer nav .menu-item-has-children').click(function (){


        let bool = true
        if($(this).hasClass('active')) { bool = false }


        $(this).parent().find('.menu-item-has-children.active ul').stop().slideToggle(400);
        $(this).parent().find('.menu-item-has-children.active').removeClass('active');


        if(bool) {
            $(this).addClass('active');
            $(this).find('ul').stop().slideToggle(400);
        }
    });

    //console.log($('footer nav .menu-item-has-children:nth-child(2)'));
    $('footer nav .menu-item-has-children:nth-child(2)').addClass('active');
    $('footer nav .menu-item-has-children:nth-child(2)').find('ul').stop().slideToggle(0);

    if(document.querySelector(".section-cartes") && document.querySelector(".pin-container")){

        const section_cartes = document.querySelector(".pin-container")
    
        if ($(window).width() < 768) {
            let temoignages = new Splide( '.section-cartes .splide', {
                type   : 'loop',
                gap : 30,
                perMove: 1,
            });
            
            temoignages.mount()
        } else {

            let nbr_slides

            if ($(window).width() > 1300) {
                nbr_slides = 3
            } else if ($(window).width() > 1000) {
                nbr_slides = 2
            } else {
                nbr_slides = 1
            }
            
            let slides = gsap.utils.toArray(section_cartes.querySelectorAll(".splide__slide"));
   
            gsap.to(slides, {
                x: -292 * (slides.length - nbr_slides),
                ease: "none",
                scrollTrigger: {
                    trigger: section_cartes,
                    scrub: 1,
                    pin: true,
                    start: "50% 50%",
                    end: "+=150%"
                }
            });
        }
    }

        

    // let vh = window.innerHeight * 0.01;
    // // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`);

    // // We listen to the resize event
    // window.addEventListener('resize', () => {
    //     // We execute the same script as before
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
});